import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { withStyles } from "@material-ui/core/styles"

import Grid from '@material-ui/core/Grid'

import ClassMarkParams from './ClassMarkParams'
import ClassMarkItems from "./ClassMarkItems"
import ImageProduct from '../All/ImageProduct'
import { createUrlAlias } from "../../utils/url"
import PropTypes from "prop-types"
import styles from "../../components/styles"


const style = theme => ({
  h1: {
    margin: '0.67em 0 0 0'
  },
  head:{
    marginBottom: '10px'
  },
  ...styles(theme),
})


const ClassMarkTemplate = ({pageContext: { classMarkData,classMarkItemData, groupName }, data, classes}) => {
  let title
  if (classMarkData.mark_type === 1) {
    title = "Кабель " + classMarkData.name
  }
  if (classMarkData.mark_type === 2) {
    title = "Провод " + classMarkData.name
  }
  // title = "0"
  return (
  <Layout>
    <SEO title={"⚡" + title + " цена, вес, ток, диаметр, характеристики, фото"}
         description={"⚡Купить " + title + " с доставкой, узнать цену, вес, ток, диаметр и характеристики"} />
    <div aria-label="breadcrumb">
      <span><Link to={`/catalog/`}>Продукция</Link></span>
      <span> > </span>
      <span><Link to={`/catalog/${createUrlAlias(groupName)}/`}>{groupName}</Link></span>
      <span> > </span>
      <span>{classMarkData.mark_type === 1 && "Кабель"} {classMarkData.mark_type === 2 && "Провод"} {classMarkData.name}</span>
    </div>
    <div className={classes.head} aria-label="articleHead">
      <h1 className={classes.h1}>{classMarkData.mark_type === 1 && "Кабель"} {classMarkData.mark_type === 2 && "Провод"} {classMarkData.name}</h1>
      <span dangerouslySetInnerHTML={{ __html: classMarkData.mark_gost }}></span>
    </div>
    <Grid container>
      <Grid item lg={2} md={3} xs={12} className={classes.imageBoxGrid}>
        <div className={classes.imageBox}>
          <ImageProduct imageInfo={data.classMarkImage} imageAlt={classMarkData.name}/>
        </div>
      </Grid>
      <Grid item lg={10} md={9} xs={12} className={classes.prodBoxGrid}>
        <Grid container className={classes.box} spacing='2'>
          {/*{classMarkData.mark_const.length > 30 &&*/}
          {/*  <Grid item lg={6} md={12}>*/}
          {/*    <div*/}
          {/*      dangerouslySetInnerHTML={{ __html: classMarkData.mark_const }}/>*/}
          {/*  </Grid>*/}
          {/*}*/}
          {classMarkData.mark_params.length > 1 &&
            <Grid item lg={6} md={12}>
              <React.Fragment>
                <h2>Характеристики {classMarkData.mark_type === 1 && "кабеля"} {classMarkData.mark_type === 2 && "провода"} {classMarkData.name}</h2>
                <ClassMarkParams data={classMarkData.mark_params}/>
              </React.Fragment>
            </Grid>
          }
          <Grid item xs={12}>
            <h2>Маркоразмеры {classMarkData.mark_type === 1 && "кабеля"} {classMarkData.mark_type === 2 && "провода"} {classMarkData.name}</h2>
            <ClassMarkItems data={classMarkItemData}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Layout>
)}

ClassMarkTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ClassMarkTemplate)

export const query = graphql`
    query($image: String!) {
      classMarkImage: file(relativePath: { eq: $image }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

