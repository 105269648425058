import React from 'react'
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"
import styles from "../../components/styles"

const style = theme => ({
  ...styles(theme),
  params: {
    fontSize: '0.9em',
    '& dt': {
      width: '55%'
    }
  },
  paramsTable: {
    // maxWidth: '640px'
  }
})


const ClassMarkParams = ({ data, classes }) => {
  return (
    <div className={[classes.AsTable + ' ' + classes.paramsTable]}>
      {data.map(mark_param => (
        <dl className={classes.params}>
          <dt>{mark_param.mark_param_name}</dt><dd>{mark_param.mark_param_value}</dd>
        </dl>
      ))}
    </div>
  )
}

ClassMarkParams.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ClassMarkParams)