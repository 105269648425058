import React from "react"
import Link from 'gatsby-link'
import _ from 'lodash'

import { createUrlAlias } from "../../utils/url"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"
import styles from "../../components/styles"

const style = theme => ({
  ...styles(theme),
  markList: {
    padding: theme.spacing(0, 0, 2, 0)
  }
})

function getSortSizes(data) {
  return _.sortBy(data, function (obj) {
    let data = obj.name.replace(',','.')
    let a = parseFloat(/\s[+-]?([0-9]*[.])?[0-9]+/.exec(data))
    let b = parseFloat(String(/х\d+/.exec(data)).replace('х',''))
    if (!b)
      b = 0
    return  a*1000+b
  });
}
function getGroupSizes(data) {
  let result =  _.groupBy(data, function (obj) {
    let data = obj.name.replace(',','.')
    let a = parseFloat(String(/\s\d+[х]/.exec(data)))
    // let a = parseFloat(String(/\s\d+[х]\d+[+]\d[х]\d+/.exec(data)))
    let b = parseFloat(String(/[+]\d+/.exec(data)))
    // console.log(a)
    if (!b)
      b = 0
    if (!a) {
      // let c = parseFloat(String(/(?<=-)\d+(?=-)|(?<=-)\d+\S\d+(?=-)/.exec(data)))
      let c = parseFloat(String(/-\d+-|-\d+\S\d+-/.exec(data)))
      // return /(?<=-)\d+(?=-)|(?<=-)\d+\S\d+(?=-)/.exec(data)
      // return c*10
      return c*10*-1
    }
    else
      return a*10+b
    // return /\d+[х]|(?<=-)\d+(?=-)|(?<=-)\d+\S\d+(?=-)/.exec(data)
  })
  return result
  // if (_.size(result) === 1)
  //   return _.chunk(data, data.length/4+1)
  // else
  //   return result
}

const ClassMarkItems = ({ data, classes }) => {
  return (
    <React.Fragment>
      {Object.values(getGroupSizes(getSortSizes(data))).map(group => (
        <ul className={[classes.list + ' ' + classes.markList]}>
          {group.map(size => (<li><Link to={`/cable/${createUrlAlias(size.name)}/`}>{size.name}</Link></li>))}
        </ul>
      ))
      }
    </React.Fragment>
  )
}

ClassMarkItems.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ClassMarkItems)